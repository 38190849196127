import { Outlet } from "react-router-dom";
import "./App.css";
import Header from "./component/Header";
import Footer from "./component/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import SummaryAPI from "./common";
import Context from "./context";
import { useDispatch } from "react-redux";
import { setUserDetail } from "./store/userSlice";
import ScrollToTop from "./component/ScrollToTop";

const App = () => {
  const dispatch = useDispatch();
  const [cartProductCount, setCardProductCount] = useState(0);
  const fetchUserDetails = async () => {
    try {
      const dataResponse = await fetch(SummaryAPI.currentUser.URL, {
        method: SummaryAPI.currentUser.method,
        credentials: "include",
      });
      const dataAPI = await dataResponse.json();
      if (dataAPI.success) {
        console.log("userDetails: ", dataAPI.data);
        dispatch(setUserDetail(dataAPI.data));
      }
    } catch (error) {
      console.error("Failed to fetch user details", error);
    }
  };

  const fetchUserAddToCart = async () => {
    try {
      const dataResponse = await fetch(SummaryAPI.countAddToCartProduct.URL, {
        method: SummaryAPI.countAddToCartProduct.method,
        credentials: "include",
      });
      const dataAPI = await dataResponse.json();
      if (dataAPI.success) {
        setCardProductCount(dataAPI.data.count);
      }
    } catch (error) {
      console.error("Failed to fetch cart product count", error);
    }
  };

  useEffect(() => {
    /** user details */
    fetchUserDetails();
    fetchUserAddToCart();
  }, []);

  // const headerHeight = "5rem";
  return (
    <>
      <Context.Provider
        value={{
          fetchUserDetails,
          cartProductCount,
          fetchUserAddToCart,
        }}
      >
        <ToastContainer
          theme="dark"
          autoClose={2000}
          closeOnClick={true}
          pauseOnHover={false}
          // newestOnTop={true}
          // hideProgressBar={true}
        />
        <Header />
        <main
          className="min-h-[calc(100vh-120px)] pt-20"
          // style={{ paddingTop: headerHeight }}
        >
          <ScrollToTop />
          <Outlet />
        </main>
        <Footer />
      </Context.Provider>
    </>
  );
};

export default App;
