import React, { useState, useRef, useEffect } from "react";
import { RiArrowLeftWideLine } from "react-icons/ri";
import { RiArrowRightWideLine } from "react-icons/ri";

import image1 from "../assest/banner/img1.webp";
import image2 from "../assest/banner/img2.webp";
import image3 from "../assest/banner/img3.jpg";
import image4 from "../assest/banner/img4.jpg";
import image5 from "../assest/banner/img5.webp";

const BannerProduct = () => {
  const images = [
    "https://via.placeholder.com/800x400?text=Slide+1",
    "https://via.placeholder.com/800x400?text=Slide+2",
    "https://via.placeholder.com/800x400?text=Slide+3",
    image1,
    image2,
    image3,
    image4,
    image5,
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const sliderRef = useRef<HTMLDivElement>(null);
  const autoSlideRef = useRef<NodeJS.Timeout | null>(null);

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    restartAutoSlide();
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    restartAutoSlide();
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging) return;

    const currentX = e.touches[0].clientX;
    const diff = startX - currentX;

    if (diff > 50) {
      handleNextSlide();
      setIsDragging(false);
    } else if (diff < -50) {
      handlePrevSlide();
      setIsDragging(false);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const startAutoSlide = () => {
    autoSlideRef.current = setInterval(() => {
      handleNextSlide();
    }, 3000); // Change slide every 3 seconds
  };

  const restartAutoSlide = () => {
    if (autoSlideRef.current) {
      clearInterval(autoSlideRef.current);
    }
    startAutoSlide();
  };

  useEffect(() => {
    startAutoSlide();
    return () => {
      if (autoSlideRef.current) {
        clearInterval(autoSlideRef.current);
      }
    };
  }, [currentSlide]);

  return (
    <div className="relative w-full mx-auto">
      <div
        className="overflow-hidden relative"
        ref={sliderRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className="flex transition-transform ease-out duration-500"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {images.map((image, index) => (
            <div className="min-w-full" key={index}>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-64 sm:h-72 md:h-80 lg:h-96 object-cover"
              />
            </div>
          ))}
        </div>

        <button
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 p-2 sm:p-3 rounded-full shadow-lg hover:bg-gray-700 transition-all"
          onClick={handlePrevSlide}
          aria-label="Previous Slide"
        >
          <RiArrowLeftWideLine className="w-6 h-6 sm:w-8 sm:h-8" />
        </button>

        <button
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 p-2 sm:p-3 rounded-full shadow-lg hover:bg-gray-700 transition-all"
          onClick={handleNextSlide}
          aria-label="Next Slide"
        >
          <RiArrowRightWideLine className="w-6 h-6 sm:w-8 sm:h-8" />
        </button>
      </div>

      <div className="flex justify-center mt-4">
        {images.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 mx-1 rounded-full ${
              currentSlide === index ? "bg-gray-800" : "bg-gray-400"
            }`}
            onClick={() => {
              setCurrentSlide(index);
              restartAutoSlide();
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default BannerProduct;
