import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Link, Outlet } from "react-router-dom";

const AdminPanel = () => {
  const userDetails = useSelector((state: RootState) => state.user.user);
  const user = {
    name: userDetails?._id
      ? userDetails.firstName + " " + userDetails.lastName
      : null,
    role: userDetails?.role ? userDetails.role : null,
  };

  return (
    <div className="flex flex-col min-h-screen">
      {/* Sidebar */}
      <aside className="bg-customBlack2 w-full">
        <div className="h-20 flex justify-center items-center flex-col">
          <div className="relative flex justify-center items-center flex-col">
            <p className="text-customGold font-semibold">{user.name}</p>
            <p className="text-sm">{user.role}</p>
          </div>
        </div>
        <nav className="flex justify-center items-center flex-row">
          <Link
            to={"allUser"}
            className="px-2 py-1 hover:text-customGoldHover hover:bg-black rounded"
          >
            All User
          </Link>
          <Link
            to={"allProduct"}
            className="px-2 py-1 hover:text-customGoldHover hover:bg-black rounded"
          >
            Products
          </Link>
        </nav>
      </aside>

      {/* Main Content */}
      <main className="flex-grow p-5">
        <Outlet />
      </main>
    </div>
  );
};

export default AdminPanel;
