import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { customToast } from "../common/helperFunction";
import { useNavigate } from "react-router-dom";

interface CheckoutItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
}

const CheckoutPage: React.FC = () => {
  const [checkoutItems, setCheckoutItems] = useState<CheckoutItem[]>([
    { id: "1", name: "Product 1", price: 100, quantity: 1 },
    { id: "2", name: "Product 2", price: 150, quantity: 2 },
  ]);

  const [paymentMethod, setPaymentMethod] = useState<string>("COD");
  const [upiId, setUpiId] = useState<string>("");
  const navigate = useNavigate();
  const userDetails = useSelector((state: RootState) => state?.user?.user);

  const calculateTotal = () => {
    return checkoutItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  useEffect(() => {
    if (!userDetails) {
      customToast("Please login before proceeding for checkout", "error");
      navigate(`/login`);
    }
  }, [userDetails]);

  const handlePlaceOrder = async () => {
    // Placeholder for backend implementation

    /*
    const response = await fetch('/api/checkout', {
      method: 'POST',
      body: JSON.stringify({
        items: checkoutItems,
        total: calculateTotal(),
        paymentDetails: {
          method: paymentMethod,
          ...(paymentMethod === "UPI" && { upiId }), // Include UPI ID if method is UPI
        },
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    const responseData = await response.json();
    if (responseData.success) {
      // Redirect to order confirmation page
    } else {
      // Handle error
    }
    */

    if (paymentMethod === "UPI" && upiId === "") {
      alert("Please enter a valid UPI ID.");
      return;
    }
    console.log("Order placed", { checkoutItems, paymentMethod, upiId });
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold text-center mb-6">Checkout</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Order Summary */}
        <div className="border border-customGold p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-4">Order Summary</h2>
          <div className="space-y-4">
            {checkoutItems.map((item) => (
              <div key={item.id} className="flex justify-between items-center">
                <p className="text-lg">{item.name}</p>
                <p className="text-lg">x{item.quantity}</p>
                <p className="text-lg">${item.price * item.quantity}</p>
              </div>
            ))}
          </div>
          <div className="mt-4 flex justify-between font-semibold text-xl">
            <p>Total:</p>
            <p>${calculateTotal()}</p>
          </div>
        </div>

        {/* Payment Details */}
        <div className="border border-customGold p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-4">Payment Information</h2>
          <form className="space-y-4">
            {/* Payment Method Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Payment Method
              </label>
              <div className="flex items-center gap-4">
                {/* Radio button for COD */}
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="COD"
                    checked={paymentMethod === "COD"}
                    onChange={() => setPaymentMethod("COD")}
                    className="peer sr-only"
                  />
                  <span className="w-4 h-4 inline-block mr-2 rounded-full border-2 border-gray-400 peer-checked:border-customGold peer-checked:bg-customGold peer-checked:ring-2 peer-checked:ring-customGold"></span>
                  Cash on Delivery
                </label>

                {/* Radio button for UPI */}
                <label className="flex items-center cursor-pointer">
                  <input
                    type="radio"
                    name="paymentMethod"
                    value="UPI"
                    checked={paymentMethod === "UPI"}
                    onChange={() => setPaymentMethod("UPI")}
                    className="peer sr-only"
                    disabled
                  />
                  <span className="w-4 h-4 inline-block mr-2 rounded-full border-2 border-gray-400 peer-checked:border-customGold peer-checked:bg-customGold peer-checked:ring-2 peer-checked:ring-customGold"></span>
                  UPI
                </label>
              </div>
            </div>

            {/* UPI ID Input (Only show if UPI is selected) */}
            {paymentMethod === "UPI" && (
              <div>
                <label
                  htmlFor="upiId"
                  className="block text-sm font-medium text-gray-700"
                >
                  UPI ID
                </label>
                <input
                  type="text"
                  id="upiId"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                  placeholder="your-upi-id@bank"
                  value={upiId}
                  onChange={(e) => setUpiId(e.target.value)}
                />
              </div>
            )}

            {/* Place Order Button */}
            <button
              type="button"
              className="bg-customGold text-white px-6 py-2 mt-4 w-full rounded-md"
              onClick={handlePlaceOrder}
            >
              Place Order
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
