import React, { useState, useEffect, useContext } from "react";
import SummaryAPI from "../common";
import { customToast } from "../common/helperFunction";
import Context from "../context";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

interface CartItem {
  id: string;
  name: string;
  imageUrl: string;
  price: number;
  quantity: number;
  size: string; // Added size
}

const Cart: React.FC = () => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const context = useContext(Context);
  const navigate = useNavigate();
  const userDetails = useSelector((state: RootState) => state?.user?.user);

  useEffect(() => {
    fetchCartDataFromDB();
  }, []);

  const fetchCartDataFromDB = async () => {
    const response = await fetch(SummaryAPI.cartProducts.URL, {
      method: SummaryAPI.cartProducts.method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const responseData = await response.json();
    console.log(responseData, "here fetching cart data");
    if (responseData.success) {
      setCartItems([...responseData.data.product]);
    } else {
      customToast(responseData.message, "error");
    }
  };

  const deleteCartDataFromDB = async (
    productId: string,
    productSize: string
  ) => {
    const response = await fetch(SummaryAPI.removeFromCartController.URL, {
      method: SummaryAPI.removeFromCartController.method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productId,
        size: productSize,
      }),
    });

    const responseData = await response.json();

    if (responseData.success) {
      customToast(responseData.message, "success");
      context && context.fetchUserAddToCart();
      setCartItems([...responseData.data.product]);
    } else {
      customToast(responseData.message, "error");
    }
  };

  const updateCartProductQuantity = async (
    productId: string,
    quantity: number,
    productSize: string
  ) => {
    console.log("response send", productId, quantity, productSize);
    const response = await fetch(SummaryAPI.updateCartQuantity.URL, {
      method: SummaryAPI.updateCartQuantity.method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productId,
        quantity,
        size: productSize,
      }),
    });

    const responseData = await response.json();

    if (responseData.success) {
      setCartItems([...responseData.data.product]);
    }
  };

  const removeItem = (id: string, productSize: string) => {
    deleteCartDataFromDB(id, productSize);
  };

  const handleBuy = (id: string) => {
    if (userDetails) {
      navigate(`/checkout/${id}`);
    } else {
      customToast("Please login before proceeding for checkout", "error");
      navigate(`/login`);
    }
  };

  const handleBuyAll = () => {
    if (userDetails) {
      navigate(`/checkout/all`);
    } else {
      customToast("Please login before proceeding for checkout", "error");
      navigate(`/login`);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold mb-6 text-center">Shopping Cart</h1>

      {cartItems.length === 0 ? (
        <p className="text-center text-lg">Your cart is empty.</p>
      ) : (
        <div>
          <div className="space-y-6">
            {cartItems.map((item: CartItem, _index: number) => (
              <div
                key={item.id + _index}
                className="flex flex-col md:flex-row items-center justify-between border rounded-lg p-4 shadow-sm space-y-4 md:space-y-0 border-customGold"
              >
                {/* Product Image */}
                <div className="w-full md:w-1/5 flex justify-center p-4">
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="w-32 h-32 object-cover rounded-md"
                  />
                </div>

                {/* Product Name, Price, and Size */}
                <div className="flex-1 flex flex-col items-center md:items-start text-center md:text-left">
                  <h2 className="text-lg font-semibold">{item.name}</h2>
                  <p className="text-gray-500">Price: ${item.price}</p>
                  {/* Display Size */}
                  <p className="text-gray-500">Size: {item.size}</p>
                </div>

                {/* Quantity Changer and Total Price */}
                <div className="flex flex-col md:flex-row items-center md:space-x-6">
                  <div className="flex items-center gap-2">
                    <button
                      className="px-3 py-1 border border-customGold rounded"
                      onClick={() =>
                        updateCartProductQuantity(
                          item.id,
                          item.quantity - 1,
                          item.size
                        )
                      }
                      disabled={item.quantity <= 1}
                    >
                      -
                    </button>
                    <span className="text-lg">{item.quantity}</span>
                    <button
                      className="px-3 py-1 border border-customGold rounded"
                      onClick={() =>
                        updateCartProductQuantity(
                          item.id,
                          item.quantity + 1,
                          item.size
                        )
                      }
                    >
                      +
                    </button>
                  </div>

                  {/* Total Price */}
                  <p className="mt-4 md:mt-0 font-semibold text-center">
                    Total: ${item.price * item.quantity}
                  </p>
                </div>

                {/* Remove Button */}
                <button
                  className="ml-4 mr-4 mt-4 md:mt-0 bg-black border border-red-600 text-red-600 hover:border-red-600 hover:text-black hover:bg-red-600 px-3 py-2 rounded"
                  onClick={() => removeItem(item.id, item.size)}
                >
                  Remove
                </button>

                {/* Buy Button */}
                <button
                  className="mt-4 md:mt-0 bg-black border border-green-600 text-green-600 px-4 py-2 rounded hover:bg-green-600 hover:text-black hover:border-green-600"
                  onClick={() => handleBuy(item.id)}
                >
                  Buy
                </button>
              </div>
            ))}
          </div>

          {/* Buy All Button */}
          <div className="mt-6 flex justify-center">
            <button
              className="bg-black text-customGold px-6 py-3 rounded border-2 border-customGold hover:bg-customGoldHover hover:text-black hover:border-black"
              onClick={handleBuyAll}
            >
              Buy All
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
