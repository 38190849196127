import { createSlice } from "@reduxjs/toolkit";

export interface CounterState {
  user: {
    createdAt: string;
    emailId: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    password: string;
    updatedAt: string;
    __v: number;
    _id: string;
    role: string;
  } | null;
}

const initialState: CounterState = {
  user: null,
};

export const userSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      console.log("user details action payload ", action.payload);
      state.user = action.payload;
      //   console.log("setting user detail", action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserDetail } = userSlice.actions;

export default userSlice.reducer;
